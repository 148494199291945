import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EMPTY, firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class WompiService {
  readonly URL_API = environment.apiEndpoint + '/wompi';
  readonly
  constructor(
    private http: HttpClient
  ) { }

  acceptanceToken(user_id: string): Promise<any>{

    return firstValueFrom(this.http.get(this.URL_API+'/acceptance-token/'+`${user_id}`));
  }


   cardNew(data): Promise<any> {
    return firstValueFrom(this.http.post(this.URL_API + '/payment-sources', data));
  }

   transaction(data): Promise<any> {
    return firstValueFrom(this.http.post(this.URL_API + '/transactions', data));
  }

  validateTransaction(id:string): Promise<any> {
    return firstValueFrom(this.http.get(`${this.URL_API}/transactions/${id}`));
  }

  async getCardToken(dataCard: any): Promise<any> {

      let auth_token = environment.wompi.public_key;
      const url = environment.wompi.url;

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      };

      const requestOptions = { headers: headers };

      const {data} = await axios.post(url +'/tokens/cards', dataCard, {
            headers: headers
          }
      )

      return data

  }

  getCards(client_id: string): Observable<any> {
    return this.http.post(this.URL_API + '/customer/cards', { client_id: client_id });
  }

    removeCard(clientId: string, pid: string): Observable<any> {
        return this.http.post(this.URL_API + '/customer/card/remove', { clientId: clientId, pid: pid });
    }
}
