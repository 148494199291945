        <form class="ion-no-padding" [formGroup]="formCardNew" (ngSubmit)="setPayment()">

          <div class="m-10 bg-white rounded-md shadow-lg overflow-hidden">
            <div class="px-6 py-4 bg-gray-900 text-white">
              <h1 class="text-lg font-bold">Agregar nueva tarjeta</h1>
            </div>
            <div class="px-6 py-4">

              <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="number">
                  Numero de Tarjeta
                </label>
                <input
                  class="appearance-none border border-gray-400 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="number" type="text" placeholder="**** **** **** ****" formControlName="number" >
                <small *ngIf="formCardNew.get('number').touched && formCardNew.get('number').errors?.required" class="text-red-700">Este campo es requerido</small>
                <small *ngIf="formCardNew.get('number').touched && formCardNew.get('number').errors?.minLength" class="text-red-700">El número de la tarjeta no es válido.</small>

                <div *ngIf="formCardNew.get('number').invalid && (formCardNew.get('number').dirty || formCardNew.get('number').touched)" class="text-red-700">
                  {{ errorMessages.creditCardNumber }}
                </div>

              </div>
              <div class="mb-4 md:flex md:justify-between">
                <div class="mb-4 md:mr-2 md:mb-0">
                  <label class="block text-gray-700 font-bold mb-2" for="exp_month">
                    MES
                  </label>
                  <input
                    class="appearance-none border border-gray-400 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="exp_month" type="text" formControlName="exp_month" placeholder="MM" required maxlength="2">
                  <small *ngIf="formCardNew.get('exp_month').touched && formCardNew.get('exp_month').errors?.required" class="text-red-700"> Este campo es requerido </small>

                  <div *ngIf="formCardNew.get('exp_month').invalid && (formCardNew.get('exp_month').dirty || formCardNew.get('exp_month').touched)" class="text-red-700">
                    {{ errorMessages.expiryMonth }}
                  </div>
                </div>
                <div class="mb-4 md:mr-2 md:mb-0">
                  <label class="block text-gray-700 font-bold mb-2" for="exp_year">
                    AÑO
                  </label>
                  <input
                    class="appearance-none border border-gray-400 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="exp_year" type="text" formControlName="exp_year" placeholder="AA" required maxlength="2">
                  <small *ngIf="formCardNew.get('exp_year').touched && formCardNew.get('exp_year').errors?.required" class="text-red-700"> Este campo es requerido </small>
                  <div *ngIf="formCardNew.get('exp_year').invalid && (formCardNew.get('exp_year').dirty || formCardNew.get('exp_year').touched)" class="text-red-700">
                    {{ errorMessages.expiryYear }}
                  </div>
                </div>
                <div class="md:ml-2">
                  <label class="block text-gray-700 font-bold mb-2" for="cvc">
                    CVV
                  </label>
                  <input
                    class="appearance-none border border-gray-400 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="cvc" type="text" formControlName="cvc" placeholder="***">
                  <small *ngIf="formCardNew.get('cvc').touched && formCardNew.get('cvc').errors?.required" class="text-red-700"> Este campo es requerido </small>
                  <div *ngIf="formCardNew.get('cvc').invalid && (formCardNew.get('cvc').dirty || formCardNew.get('cvc').touched)" class="text-red-700">
                    {{ errorMessages.cvv }}
                  </div>
                  <div *ngIf="formCardNew.get('cvc').invalid && (formCardNew.get('cvc').dirty || formCardNew.get('cvc').touched)" class="text-red-700">
                    {{ errorMessages.cvv }}
                  </div>
                </div>

                <div class="md:ml-2">
                <label for="installments" class="block text-gray-700 font-bold mb-2">Cuotas</label>
                <select id="installments" formControlName="installments" class="appearance-none border border-gray-400 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  <option selected>Cuotas</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                  <small *ngIf="formCardNew.get('installments').touched && formCardNew.get('installments').errors?.required" class="text-red-700"> Este campo es requerido </small>
                  <small *ngIf="formCardNew.get('installments').touched && formCardNew.get('installments').errors?.validator" class="text-red-700"> Selecciona entre 1 - 12 cuotas. </small>
                </div>
              </div>



              <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="card_holder">
                  Nombre del titular
                </label>
                <input
                  class="appearance-none border border-gray-400 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text" id="card_holder" formControlName="card_holder" placeholder="Nombre del titular">
                <small *ngIf="formCardNew.get('card_holder').touched && formCardNew.get('card_holder').errors?.required" class="text-red-700">Este campo es requerido</small>
              </div>

              <div class="flex items-center mb-4 justify-center max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-400">
                <input id="saveCard" [formControl]="saveCard" checked="true" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="saveCard" class="ml-2 text-sm font-medium text-black">Guardar datos para futuras compras.</label>
              </div>

              <div class="flex items-center mb-4 justify-center max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-400">
                <input id="acceptTokenCheck" [formControl]="acceptTokenCheck" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="acceptTokenCheck" class="ml-2 text-sm font-medium text-purple-800"> <a [href]="acceptanceToken.permalink" target="_blank">Acepto haber leído la política de privacidad.</a></label>
              </div>

              <div class="grid justify-center">
                <button class="bg-fuchsia-700 hover:bg-blue-600 disabled:bg-fuchsia-400 text-white font-bold py-2 px-4 rounded-full" type="submit" [disabled]="!formCardNew.valid">
                  Pagar
                </button>
                <p *ngIf="!formCardNew.valid" class="text-red-700" (click)="setPayment()">Completa todos los campos para habilitar el boton!</p>
              </div>

            </div>
          </div>


        </form>
