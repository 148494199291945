import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  ActionSheetController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

import { OpenpayErrorHandlerService } from 'src/app/services/openpay-error-handler.service';
import { ToastService } from 'src/app/services/notifications/toast.service';
import { LoadingService } from 'src/app/services/notifications/loading.service';
import { BuyTableService } from 'src/app/services/buy-table.service';
import { ValidationStockpileService } from 'src/app/services/validators/validation-stockpile.service';
import {WompiService} from "../../../services/wompi.service";
import {AuthService} from "../../../services/auth.service";
import {SweetalertService} from "../../../services/notifications/sweetalert.service";
import {OrderService} from "../../../services/order.service";
import {Router} from "@angular/router";
@Component({
  selector: 'app-wompi-checkout-card-list',
  templateUrl: './wompi-checkout-card-list.component.html',
  styleUrls: ['./wompi-checkout-card-list.component.scss'],
})
export class WompiCheckoutCardListComponent implements OnInit {
  @Input() customer_id: any;
  @Input() payment: any;
  @Input() idTable: any;

  @Output() paymentEvent = new EventEmitter<any>();
  @Output() changeHidden = new EventEmitter<any>();
  @Output() hiddenLoader = new EventEmitter<any>();

  dataSourceCards: any;
  sponsor: any;
  indexSelected: number;
  swiper: any;

  user: any;
  paymentData: any;
  validationData: any;
  constructor(
      private loadingController: LoadingController,
      private wompiService: WompiService,
      private openpayErrorHandlerService: OpenpayErrorHandlerService,
      private loadingService: LoadingService,
      private buyTableService: BuyTableService,
      private toastService: ToastService,
      private validationStockpileService: ValidationStockpileService,
      private actionSheetControllerService: ActionSheetController,
      private alertController: AlertController,
      private modalController: ModalController,
      private authService: AuthService,
      private sweetAlertService: SweetalertService,
      private orderService: OrderService,
      private router: Router,

  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    this.getCards();
    this.sponsor = JSON.parse(localStorage.getItem('sponsor'));

  }

  getCards() {
    this.wompiService
        .getCards(this.customer_id)
        .subscribe((response) => {
          if (response) {
            this.hiddenLoader.emit();
            this.dataSourceCards = response.data.list;
            if (this.dataSourceCards.length == 0) {
              this.changeHidden.emit(true);
            }
          } else {
            this.hiddenLoader.emit();
            this.dataSourceCards = [];
            this.changeHidden.emit(true);
          }
        });
  }


  async cardEvent(card: any) {
    if (this.idTable) {
      let response: any = await this.findBuyTableByTableId();
      if (response.code == 200) {
        await this.deletePaymentStockpileAndOrder(this.payment.order);

        await this.alert();
      } else {
        await this.cardNew(card);
      }
    } else {
      await this.cardNew(card);
    }
  }


  async deletePaymentStockpileAndOrder(order: any) {
    //Buscar los pagos por orden y estado pendiente
    let data = {
      state: 'PENDIENTE',
      order: order._id,
    };

    let responsePayment: any =
        await this.validationStockpileService.paymentsSearchByOrderAndState(data);

    if (responsePayment.code == 200) {
      let paymentExpired = await responsePayment.data.list;

      //Elimina los pagos pendientes
      let responsePaymentDelete: any =
          await this.validationStockpileService.paymentsDelete(paymentExpired);

      //Valida si se eliminaron los pagos correctamente
      console.log('Payment delete::', responsePaymentDelete.message);
    }

    //Buscar las reservas por orden
    let response: any =
        await this.validationStockpileService.stockpileSearchByOrder(order._id);
    if (response.code == 200) {
      //Elimina la reserva
      await this.validationStockpileService.stockpileDelete(response.data.list);
    } else {
      console.log('stockpileSearchByOrder::', response.message);
    }

    //Edito la orden
    let responseOrderEdit: any =
        await this.validationStockpileService.orderEdit(order._id);
    console.log('Order edit::', responseOrderEdit.message);
  }

  async findBuyTableByTableId() {
    return new Promise((resolve, reject) => {
      this.buyTableService.findBuyTableByTableId(this.idTable).subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject({
              type: 'error',
              code: 400,
              message: 'Error al realizar la consulta: ' + error.message,
            });
          }
      );
    });
  }



  async cardNew(card: any) {

    this.payment.order.details.forEach((element) => {
      this.orderService.availableTickets(this.payment.order.event, element.category.prefix).subscribe({
        next: async (response) => {

          console.log("Available tickets: ", response.data.available)
          console.log("Quantity: ", element.quantity)

          if (response.data.available < 0 ) {
            this.sweetAlertService.message({
              type: 'warning',
              message: 'Ya no hay disponibilidad en esta categoría. Intenta con otra.',
            })
            await this.deletePaymentStockpileAndOrder(this.payment.order);
            await this.router.navigate(["ticket/client/buy/" + this.sponsor.code]);
          }
        },
        error: (error) => {
          console.log("Error !!", error.message);
        }
      });
    });

    if (this.payment.total < 1500 ) {
      await this.toastService.open("El monto mínimo de compra es de $1.500 COP.", "top", 2000);
      return;
    }


    const sleep = (ms) => new Promise(r => setTimeout(r, ms));


    const responseConfirm = await this.sweetAlertService.confirm(
      '¿Está seguro?',
      'Desea pagar con esta tarjeta'
    );

    if (responseConfirm.code == 200) {


      await this.loadingService.open('Realizando compra, por favor espere.');

      const pymtPayload = {
        amount_in_cents: Math.round(this.payment.total * 100),
        currency: "COP",
        customer_email: this.user._user.email,
        payment_method: {
          installments: 1
        },
        reference: this.payment.number,
        payment_source_id: card.source_id,
        recurrent: true
      }

      await this.makePayment(pymtPayload);

      await sleep(1000)

      console.log("4. Making validation...")

      let tries = 0;

      do {

        tries = tries + 1

        console.log(this.paymentData)
        await this.validateTransaction(this.paymentData.data.id)

        if (this.validationData["status"] === "DECLINED") {
          console.log("4. Making validation... Declined!")
          await this.toastService.open("Transacción Rechazada por el banco.", "top", 4000);
          break;
        }
        if(this.validationData["status"] === "APPROVED"){
          console.log("4. Making validation... Done!")
          break;
        } else {
          await sleep(1000);
        }
        console.log("4. Trying " + tries)
      } while (tries<10);


      await this.loadingController.dismiss();

      this.paymentEvent.emit({
        wompi_response: this.validationData,
      });
    }
  }

  async makePayment(data:any) {

    try {

      await this.wompiService.transaction(data).then(data =>{
        this.paymentData = data
      }).catch(e => {
        console.log("Error generating Pymt")
        console.log(e)
        this.changeHidden.emit(true)
        this.loadingService.loading.dismiss();
        this.toastService.open("Error generando el pago. No se aplicaron cargos.", "top", 4000);
      }).finally(()=>{
        console.log("4. Generating Pymt... Done!")
      });

    } catch (error) {
      throw error;
    }

  }

  async validateTransaction(id:string) {
    console.log("4 Validando pago...")
    try {

      this.validationData  = await this.wompiService.validateTransaction(id)

      console.log(this.paymentData)
      console.log("4......  :)")

    } catch (error) {
      throw error;
    }


  }


  async alert() {
    const alert = await this.alertController.create({
      cssClass: 'delete-invited',
      header: '¡Mesa Vendida!',
      message:
          '<p class="ion-text-justify">Esta mesa ya no se encuentra disponible</p>',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          role: 'cancel',
          handler: () => {
            this.goToHome();
          },
        },
      ],

      animated: true,
    });

    await alert.present();
  }

  setSwiperInstance(ev: any) {
    this.swiper = ev;
    this.indexSelected = this.swiper.realIndex;
  }

  slideChange() {
    if (this.swiper) {
      this.indexSelected = this.swiper.realIndex;
    }
  }

  goToNewCard() {
    this.changeHidden.emit(true);
  }

  splitCardNumber() {}


  async deleteCard(card: any) {

    const actionSheet = await this.actionSheetControllerService.create({
      header: 'Eliminar tarjeta',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'Confirmar',
          role: 'destructive',
          icon: 'checkmark-outline',
          handler: async () => {
            await this.loadingService.open('Eliminando tarjeta');

            this.wompiService.removeCard(this.customer_id, card.source_id).subscribe(
                (response) => {
                  this.loadingService.loading.dismiss();
                  if (response.type == "success") {
                    this.getCards();
                  } else {
                    this.openpayErrorHandlerService.handler(
                        response.error,
                        response.error.description
                    );
                  }
                },
                (error) => {
                  this.toastService.open('Error al eliminar tarjeta', 'top');
                }
            );
          },
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          icon: 'close',
        },
      ],
    });

    await actionSheet.present();
  }



  goToHome() {
    this.modalController.dismiss({
      type: 'warning',
      code: 400,
      message: 'Salir',
    });
  }
}
