import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnySourceData } from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  readonly URL_API = environment.apiEndpoint + '/payment';
  constructor(private http: HttpClient) { }

  show(id: string): Observable<any> {
    return this.http.get(this.URL_API+ '/show/' + `${id}`);
  }

  edit(data: any, id: string): Observable<any> {
    return this.http.patch(this.URL_API + '/edit/' + `${id}`, data);
  }

  delete(id):Observable<any>{
    return this.http.delete(this.URL_API + '/delete/' + `${id}`)
  }

  searchByFiltersAndType(data): Observable<any> {
    return this.http.post(this.URL_API + '/search/filters/type', data);
  }

  showByNumber(number: string): Observable<any> {
    return this.http.get(this.URL_API + '/show/number/'  + `${number}`);
  }

  searchByOrderAndState(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/search/order/state', data)
  }

  searchByOrderClientAndState(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/search/order/client/state', data)
  }

  findOneOrder(data: any): Observable<any>{
    return this.http.post(this.URL_API+ '/find/order', data)
  }

  searchByClientAndState(data: any):Observable<any> {
    return this.http.post(this.URL_API + '/search/client/state', data);
  }


}
