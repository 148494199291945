import { Injectable } from '@angular/core';

import { OrderService } from '../order.service';
import { PaymentService } from '../payment.service';
import { ReservedService } from '../reserved.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationStockpileService {
  constructor(
    private orderService: OrderService,
    private paymentService: PaymentService,
    private reservedService: ReservedService
  ) {}

  async ordersExpiredAndPending() {
    return new Promise((resolve, reject) => {
      let data = {
        time: 10,
      };

      this.orderService.quantityReservedForTime(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async paymentsSearchByOrderAndState(data: any) {
    return new Promise((resolve, reject) => {
      this.paymentService.searchByOrderAndState(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async paymentsSearchByOrderClientAndState(data: any) {
    return new Promise((resolve, reject) => {
      this.paymentService.searchByOrderClientAndState(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async paymentsDelete(payments: any) {
    try {
      return new Promise(async (resolve, reject) => {
        for await (const payment of payments) {
          this.paymentService.delete(payment._id).subscribe(
            async (response) => {
              if (response.code == 200) {
                resolve({
                  type: 'success',
                  code: 200,
                  message:
                    'Perfecto!! todos los pagos fueron eliminados satisfactoriamente.',
                });
              }
            },
            (error) => {
              reject({
                type: 'error',
                code: 400,
                message: 'Error!!  pagos no se pudieron eliminar.',
                error,
              });
            }
          );
        }
      });
    } catch (error) {
      console.log('Error', error);
    }
  }

  async stockpileSearchByOrder(idOrder: string) {
    return new Promise((resolve, reject) => {
      this.reservedService.listByOrder(idOrder).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async stockpileDelete(stockpiles: any) {
    return new Promise(async (resolve, reject) => {
      for await (const stockpile of stockpiles) {
        this.reservedService.delete(stockpile._id).subscribe(
          (response) => {
            if (response.code == 200) {
              resolve({
                type: 'success',
                code: 200,
                message:
                  'Perfecto!! todos las reservas fueron eliminados satisfactoriamente.',
              });
            }
          },
          (error) => {
            reject({
              type: 'error',
              code: 400,
              message: 'Error!! reservas no se pudieron eliminar.',
              error,
            });
          }
        );
      }
    });
  }

  async orderEdit(orderId: any) {
    return new Promise(async (resolve, reject) => {
      let data = {
        state: 'ELIMINADA',
      };

      this.orderService.edit(data, orderId).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async tablesExpiredAndPending(data) {
    return new Promise((resolve, reject) => {
      this.reservedService.quantityReservedTableForTime(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async showOrder(idOrder) {
    return new Promise((resolve, reject) => {
      this.orderService.show(idOrder).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async searchByClientAndState(data: any) {
    return new Promise((resolve, reject) => {
      this.paymentService.searchByClientAndState(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async checheckReservedTable(data: any) {
    return new Promise((resolve, reject) => {
      this.reservedService.findOneByTableReserved(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  /**
   * Consulta el total de reservas según el esquema:
   *
   * code: categoryCode
   * time: time
   * type: type
   * event: idEvent
   * gteLte: 'lte', 'gte', 'lt', 'gt'
   */
  async totalReservedForTime(
    categoryCode: number,
    time: number,
    type: string,
    idEvent: string,    
  ) {
    return new Promise((resolve, reject) => {
      //@data
      let data = {
        code: categoryCode,
        time: time,
        type: type,
        event: idEvent,        
      };

      this.reservedService.totalReservedForTime(data).subscribe(
        (response) => {
          resolve(response);         
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  async quantityReservedForTime(data) {
    return new Promise((resolve, reject) => {
      this.reservedService.removeAllForTimeExpired({ time: 10 }).subscribe(
        (response) => {
          resolve(response);          
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  newReserved(data: any) {
    return new Promise((resolve, reject) => {
      this.reservedService.new(data).subscribe(
        (response) => {
          resolve(response);          
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  //Consulto la reserva por categoria de boleta, evento y cliente
  async findOneByCategoryEventAndClient(data: any) {
    return new Promise((resolve, reject) => {
      this.reservedService.findOneByCategoryEventAndClient(data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }

  editStockpile(idStockpile, data){
    return new Promise((resolve, reject) => {
      this.reservedService.edit(idStockpile, data).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject({
            type: 'error',
            code: 400,
            message: 'Error al realizar la consulta: ' + error.message,
          });
        }
      );
    });
  }
}