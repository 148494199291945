import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientService } from "src/app/services/client.service";
import { LoadingService } from "src/app/services/notifications/loading.service";
import { ToastService } from 'src/app/services/notifications/toast.service';
import {WompiService} from "../../../services/wompi.service";
import {set} from "@angular/fire/database";
import {OrderService} from "../../../services/order.service";
import {SweetalertService} from "../../../services/notifications/sweetalert.service";
import {ValidationStockpileService} from "../../../services/validators/validation-stockpile.service";
import {Router} from "@angular/router";

interface acceptanceTokenModel{
  acceptance_token: string;
  permalink: string;
}
@Component({
  selector: 'app-wompi-checkout-card-new',
  templateUrl: './wompi-checkout-card-new.component.html',
  styleUrls: ['./wompi-checkout-card-new.component.scss'],
})
export class WompiCheckoutCardNewComponent implements OnInit {
  @Input() user: any;
  @Input() payment: any;

  @Output() paymentEvent = new EventEmitter<any>();
  @Output() changeHidden = new EventEmitter<any>();

  client: any;
  sponsor: any;
  formCardNew: FormGroup;

  errorMessages = {
    creditCardNumber: 'Número de tarjeta inválido',
    expiryMonth: 'Fecha de expiración inválida',
    expiryYear: 'Año de expiración inválido',
    cvv: 'CVV inválido',
  };



  saveCard = new FormControl(true);

  acceptTokenCheck = new FormControl(false);


  dataSourceCards: any = [];

  acceptanceToken: acceptanceTokenModel = {
    acceptance_token: "",
    permalink: "#",
  };
  cardToken: any;

  paymentSourcesData: any;
  paymentData: any;
  validationData: any;

  constructor(
    private formBuilder: FormBuilder,
    private wompiService: WompiService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private orderService: OrderService,
    private validationStockpileService: ValidationStockpileService,
    private router: Router,
    private sweetAlertService: SweetalertService,
  ) { }

  ngOnInit() {

    this.sponsor = JSON.parse(localStorage.getItem('sponsor'));

    this.client = this.user._client;

    console.log("1. Generating ATk...")
    this.wompiService.acceptanceToken(this.client._id).then(data=>{
      this.acceptanceToken = data;
    }).catch(async e => {
      console.log("Error generating ATkn")
      console.log(e)
      this.changeHidden.emit(true)
      this.loadingService.loading.dismiss();
      await this.toastService.showError("Error Generando Token de Aceptación.");
    }).finally(()=>{
      console.log("1. Generating ATk... Done!")
    });

    this.formCardNew = this.formBuilder.group({
      card_holder: new FormControl(this.user._person.fullname, [Validators.required]),
      number: new FormControl(null, [Validators.required, Validators.minLength(14), Validators.maxLength(16),Validators.pattern("^[0-9]*$")]),
      cvc: new FormControl(null, [Validators.required, Validators.minLength(3),Validators.maxLength(4),Validators.pattern("^[0-9]*$")]),
      exp_month: new FormControl(null, [Validators.minLength(2),Validators.maxLength(2),Validators.pattern("^[0-9]*$")]),
      exp_year: new FormControl(null, [Validators.minLength(2),Validators.maxLength(2),Validators.pattern("^[0-9]*$")]),
      installments: new FormControl(1, [Validators.minLength(1), Validators.maxLength(2)])
    });

  }

  async deletePaymentStockpileAndOrder(order: any) {
    //Buscar los pagos por orden y estado pendiente
    let data = {
      state: 'PENDIENTE',
      order: order._id,
    };

    let responsePayment: any =
      await this.validationStockpileService.paymentsSearchByOrderAndState(data);

    if (responsePayment.code == 200) {
      let paymentExpired = await responsePayment.data.list;

      //Elimina los pagos pendientes
      let responsePaymentDelete: any =
        await this.validationStockpileService.paymentsDelete(paymentExpired);

      //Valida si se eliminaron los pagos correctamente
      console.log('Payment delete::', responsePaymentDelete.message);
    }

    //Buscar las reservas por orden
    let response: any =
      await this.validationStockpileService.stockpileSearchByOrder(order._id);
    if (response.code == 200) {
      //Elimina la reserva
      await this.validationStockpileService.stockpileDelete(response.data.list);
    } else {
      console.log('stockpileSearchByOrder::', response.message);
    }

    //Edito la orden
    let responseOrderEdit: any =
      await this.validationStockpileService.orderEdit(order._id);
    console.log('Order edit::', responseOrderEdit.message);
  }

  async setPayment() {

    if(!this.isLuhnValid(this.formCardNew.get('number').value)){
      console.log("Luhn Check Fail")
        await this.toastService.showError("Numero de tarjeta inválido.");
        return;
    }

    if (this.acceptTokenCheck.value == false) {
      await this.toastService.showError("No se aceptó la politica de privacidad.");
      return;
    }

    if (this.formCardNew.get('exp_month').value == null || this.formCardNew.get('exp_month').value > 12) {
      await this.toastService.showError("Mes inválido.");
      return;
    }

    if (this.formCardNew.get('exp_year').value == null || this.formCardNew.get('exp_year').value < 23) {
      await this.toastService.showError("Año inválido.");
      return;
    }

    if (this.formCardNew.get('number').value.length < 14) {
      await this.toastService.showError("Numero de tarjeta inválido.");
      return;
    }


    if (this.payment.total < 1500 ) {
        await this.toastService.showError("El monto mínimo de compra es de $1.500 COP.");
        return;
    }


    if (!this.formCardNew.valid || this.dataSourceCards.length == 3) {
      if (!this.formCardNew) {
        await this.toastService.showError("Hay campos vacíos.");
        return;
      }

      if (this.dataSourceCards.length == 3) {
        await this.toastService.showError("Por seguridad solo puedes tener hasta tres tarjetas, por favor elimina alguna para registrar una nueva.");
        return;
      }
    }



    this.payment.order.details.forEach((element) => {
      this.orderService.availableTickets(this.payment.order.event, element.category.prefix).subscribe({
        next: async (response) => {

          console.log("Available tickets: ", response.data.available)
          console.log("Quantity: ", element.quantity)

          if (response.data.available < 0 ) {

            this.sweetAlertService.message({
              type: 'warning',
              message: 'Ya no hay disponibilidad en esta categoría. Intenta con otra.',
            })
            await this.deletePaymentStockpileAndOrder(this.payment.order);
            await this.router.navigate(["ticket/client/buy/" + this.sponsor.code]);
          } else {
            if(this.formCardNew.valid) {
              await this.cmplPayment();
            }
          }
        },
        error: (error) => {
          console.log("Error !!", error.message);
        }
      });
    });


  }


  async cmplPayment() {

    const sleep = (ms) => new Promise(r => setTimeout(r, ms));

    await this.loadingService.open("Realizando compra, por favor espera...");

    console.log("2. Generating TknCrd...")

    await this.wompiService.getCardToken({
      "number": this.formCardNew.value.number.toString(),
      "card_holder": this.formCardNew.value.card_holder,
      "exp_year": this.formCardNew.value.exp_year.toString(),
      "exp_month": this.formCardNew.value.exp_month.toString(),
      "cvc": this.formCardNew.value.cvc.toString(),
    }).then(data => {
      this.cardToken = data;
    }).catch(e => {
      console.log("Error generating TknCrd.")
      console.log(e)
      this.changeHidden.emit(true)
      this.loadingService.dismiss();
      this.toastService.showError("Datos de Tarjeta inválidos.");
    }).finally(()=>{
      console.log("2. Generating TknCrd... Done!")
    });

    //Revisar modelo de no guardado.

      console.log("3. Generating SrcPymt...")
      await this.saveNewCard({
        data: {
          "type": "CARD",
          "token": this.cardToken.data.id,
          "customer_email": this.user._user.email,
          "acceptance_token": this.acceptanceToken.acceptance_token,
        },
        client: this.client._id,
        brand: this.cardToken.data.brand
      });

      console.log("4. Generating Pymt...")
      const pymtPayloadSvd = {
        amount_in_cents: Math.round(this.payment.total * 100),
        currency: "COP",
        customer_email: this.user._user.email,
        payment_method: {
          installments: this.formCardNew.value.installments
        },
        reference: this.payment.number,
        payment_source_id: this.paymentSourcesData.data.id,
        recurrent: true
      }
      console.log(pymtPayloadSvd)
      await this.makePayment(pymtPayloadSvd);

      await sleep(1000)

      console.log("4. Making validation...")

      let triesSvd = 0;

      do {

        triesSvd = triesSvd + 1

        await this.validateTransaction(this.paymentData.data.id)

        if (this.validationData["status"] === "DECLINED") {
          console.log("4. Making validation... Declined!")
          await this.toastService.showError("Transacción Rechazada por el banco.");
          this.wompiService.removeCard(this.client._id, this.paymentSourcesData.data.id).subscribe(
            data => {
              console.log("Card removed.");
            }
          )

          break;
        }
        if(this.validationData["status"] === "APPROVED"){
          console.log("4. Making validation... Done!")
          break;
        } else {
          await sleep(1000);
        }
        console.log("4. Trying " + triesSvd)
      } while (triesSvd<10);


      this.loadingService.loading.dismiss();

      this.paymentEvent.emit({
        wompi_response: this.validationData,
      });



    this.loadingService.loading.dismiss();

  }

  async saveNewCard(data:any):Promise<any> {
    try {
      await this.wompiService.cardNew(data).then(data => {
        this.paymentSourcesData = data
      }).catch(e => {
        console.log("Error generating SrcPymt...")
        console.log(e)
        this.changeHidden.emit(true)
        this.loadingService.loading.dismiss();
        this.toastService.showError("Error intentando guardar la tarjeta. ");
        this.sweetAlertService.message({
          type: 'warning',
          message: 'Error intentando guardar la tarjeta: ' + e.error.data.error_message,
        })
      }).finally(()=>{
        console.log("3. Generating SrcPymt...Done!")
      })

    } catch (error) {
      throw error;
    }


  }

  async makePayment(data:any) {

    try {

      await this.wompiService.transaction(data).then(data =>{
          this.paymentData = data
      }).catch(e => {
        console.log("Error generating Pymt")
        console.log(e)
        this.changeHidden.emit(true)
        this.loadingService.loading.dismiss();
        this.toastService.showError("Error generando el pago. No se aplicaron cargos.");
      }).finally(()=>{
        console.log("4. Generating Pymt... Done!")
      });

    } catch (error) {
      throw error;
    }

  }

  async validateTransaction(id:string) {
    console.log("4 Validando pago...")
    try {

      this.validationData  = await this.wompiService.validateTransaction(id)

      console.log(this.paymentData)
      console.log("4......  :)")

    } catch (error) {
      throw error;
    }


  }

  isLuhnValid(cardNumber: string): boolean {
    cardNumber = cardNumber.replace(/\s/g, ''); // Remove spaces
    if (!/^[0-9]{12,19}$/.test(cardNumber)) {
      return false; // Invalid format
    }

    let sum = 0;
    let isAlternate = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (isAlternate) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      isAlternate = !isAlternate;
    }

    return sum % 10 === 0;
  }

  protected readonly set = set;
}
