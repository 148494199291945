<ng-container class="container">
  <div class="loader" *ngIf="loader == true">Cargando...</div>

  <app-wompi-checkout-card-list *ngIf="card_list_hidden == false && payment" [customer_id]="user._client._id"  [idTable]="idTable" [payment]="payment"
    (paymentEvent)="emitPayment($event)" (changeHidden)="changeHidden($event, 'list')" (hiddenLoader)="hiddenLoader()">
  </app-wompi-checkout-card-list>

  <app-wompi-checkout-card-new *ngIf="card_new_hidden == false && payment" [user]="user" [payment]="payment" (paymentEvent)="emitPayment($event)"
    (changeHidden)="changeHidden($event, 'new')">
  </app-wompi-checkout-card-new>

  <div class="flex justify-center bg-gray-200/80 text-center">
    <img ngSrc="./assets/images/Wompi.png" alt="Logo wompi" height="113" width="200">
  </div>
</ng-container>
