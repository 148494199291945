import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BuyTableService {
  readonly apiUrl = environment.apiEndpoint + '/buy-table/';

  constructor(private http: HttpClient) { }

  new(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'new', data);
  }

  createByOrder(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'create/order', data);
  }

  getEvent(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'get/event/' + `${id}`);
  }

  listTableByClientAndState(data: any){
    return this.http.post<any>(this.apiUrl + 'list/table/client/state', data);
  }

  listBuyerClientsByEvent(data: any):Observable<any>{
    return this.http.post<any>(this.apiUrl + 'list/buyer/clients/by/event/id', data);
  }

  findBuyTableByTableId(idTable: string):Observable<any>{
    return this.http.get<any>(this.apiUrl + 'find/by/table/' + idTable);
  }
}
