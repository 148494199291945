//ng build --prod --base-href /sw/eventhub/frontend/dist/
//ng build --prod --base-href https://eventhub.com.co/

export const environment = {

  //apiEndpoint: 'http://localhost:3000', // Backend Local
  //apiEndpoint: 'https://api.eventhub.com.co', // Backend gcloud production
  apiEndpoint: ' https://eventhub-back-test-r2vvmtwg5q-uk.a.run.app', // Backend gclodu pruebas

  //site: 'https://sonorous-house-337921.uc.r.appspot.com', // Front pruebas gcloud
  site: 'eventhub.com.co', //Front produccion gcloud
  //site: 'localhost:4200', //Front local
  bucketUrl: 'https://d8a73z95r35xb.cloudfront.net/',

  production: false,
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZ29sZGVubWFjZWRldiIsImEiOiJjazBnYnFxb3AwNWdqM2JuNWRwazZxMWY0In0._I3zpcM9QTHNqcXWjWpD6Q',
    mapStyle: 'mapbox://styles/goldenmacedev/ck49vpeap08b61cnubglcag7l'
  },
  openpay: {
    //test
    //merchant_id: "mkhgxumuc2olejyqfxhy",
    //public_key: "pk_749639c4c36f4af6aeedd55f709be1f1"
    //prod
    merchant_id: 'mcpg7bukyqzaq593uevw',
    public_key: 'pk_4704f7b735184790add6825d7d92601f'
  },
  wompi: {
    //test
    //url: "https://sandbox.wompi.co/v1",
    //public_key: "pub_test_0YEFdO3kIz7mQwbwoVOCdSw8XgeTeVKt"
    //prod
    url: "https://production.wompi.co/v1",
    public_key: "pub_prod_6UQ3fTeVpdTvSfaE9jWkUPyfqe3BGEbh"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
