<div class="grid justify-center">
    <div class="flex flex-col justify-center items-center px-6">
        <div class="m-10 flex flex-col justify-center">
              <img ngSrc="assets/images/logo.svg" alt="" class="img-fluid" height="130" width="235">
        </div>
            <div class="col-lg-12">
              <div class="text-center mb-5">
                <h4 class="text-uppercase text-black dark:text-white font-bold">¡Eventos Exclusivos!</h4>
              </div>
            </div>
    </div>
</div>
