import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {Router} from '@angular/router'
import {environment} from '../../environments/environment';
import jwt_decode from "jwt-decode";
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly URL_API = environment.apiEndpoint;

  constructor(private http:HttpClient, private router:Router) { }

  signUp(user){
    return this.http.post<any>(this.URL_API+'/user/signup/partner', user);
  }

  signUpClient(user) {
    return this.http.post<any>(this.URL_API + '/user/signup/client', user);
  }

  signInWeb(user){
    return this.http.post<any>(this.URL_API+'/user/signIn/web', user);
  }

  signInApp(user){
    return this.http.post<any>(this.URL_API+'/user/signIn/app', user);
  }

  searchByEmailWeb(email):Observable<any>{
    return this.http.get(this.URL_API + '/user/search/by/email/web/' + `${email}`);
  }

  confirmEmail(data): Observable<any>{
    return this.http.patch(this.URL_API + '/user/confirm/email', data);
  }

  confirmData(user){
    return this.http.post<any>(this.URL_API+'/user/confirm/data/web', user);
  }

  loggedIn(){
    return !!localStorage.getItem('token');
  }

  getToken(){
    return localStorage.getItem('token');
  }

  getUser(){
    if (localStorage.getItem('token')) {
      return jwt_decode(localStorage.getItem('token'));
    }else{
      return null;
    }
  }

  logOut(){
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(['/account/sign-in']);
  }

  getTokenFormPerson(id): Observable<any> {
    return this.http.get(this.URL_API + '/user/get/token/from/person/app/' + `${id}`);
  }

  updateToken(id: string) {
    this.getTokenFormPerson(id).subscribe(response => {
      if (response.code == 200) {
        localStorage.setItem('token', response.data.token);

        console.log('getTokenFormPerson', response);

      }
    });
  }

}
