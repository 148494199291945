import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  constructor() { }

  ///Activa mensaje de espera con un titulo y contenido
  loading(title: string, html: string) {
    this.close();

    Swal.fire({
      title: title,
      html: html,
      showConfirmButton: false,
      /*
      didOpen: () => {
        Swal.showLoading()
      }
      */
    });
  }

  //Activa mensaje según la respuesta configurada
  message(response: any) {
    this.close();

    Swal.fire({
      icon: response.type,
      title: response.message,
      showConfirmButton: true,
      timer: 3000
    });
  }

  confirm(title: string, text: string): any {
    return new Promise(function (resolve, reject) {
      Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed == true) {

          let response = {
            type: 'success',
            code: 200,
            message: 'Confirmación realizada satisfactoriamente.',
            data: result
          }

          resolve(response);
        } else {
          let response = {
            type: 'warning',
            code: 201,
            message: 'Cancelar. ',
          }
          resolve(response);
        }
      });
    });
  }

  close() {
    Swal.close();
  }
}
