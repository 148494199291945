import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class OpenpayErrorHandlerService {
  handler(code: number, description: string) {
    let message: string;

    switch (code) {
      case 1006:
        message = "El id de la orden ya ha sido procesado";
        break;

      case 3001:
        message = "La tarjeta fue rechazada.";
        break;

      case 3002:
        message = "La tarjeta fue rechazada."; //La tarjeta ha expirado
        break;

      case 3003:
        message = "La tarjeta fue rechazada."; //La tarjeta no tiene fondos suficientes.
        break;

      case 3004:
        message = "La tarjeta fue rechazada."; //La tarjeta ha sido identificada como una tarjeta robada
        break;

      case 3005:
        message = "La tarjeta fue rechazada."; //La tarjeta ha sido rechazada por el sistema antifraudes
        break;

      default:
        message = "Error desconicido . " + description;
        break;
    }
    return message;
  }
}
