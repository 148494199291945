import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservedService {
  readonly URL_API = environment.apiEndpoint + '/stockpile';

  constructor( private http:HttpClient ) { }

  new(data):Observable<any> {
    return this.http.post(this.URL_API + '/new', data);
  }

  list(id):Observable<any> {
    return this.http.get(this.URL_API + '/list/' + `${id}`);
  }

  show(id):Observable<any>{
    return this.http.get(this.URL_API + '/show/' + `${id}`)
  }

  delete(id):Observable<any>{
    return this.http.delete(this.URL_API + '/delete/' + `${id}`)
  }

  edit(id: string, data):Observable<any>{
    return this.http.put(this.URL_API + '/edit/' + `${id}`, data);
  }

  findOneByCategoryEventAndClient(data: any):Observable<any> {
    return this.http.post(this.URL_API + '/list/event/client', data);
  }

  quantityAvailableByCategoryEvent(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/available/reserved/category/event', data);
  }

  quantityReservedForTime(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/get/time/reserved', data);
  }

  listByOrder(idOrder: string): Observable<any>{
    return this.http.get(this.URL_API+ '/list/order/' + `${idOrder}`)
  }

  quantityReservedTableForTime(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/get/time/reserved/table', data);
  }

  findOneByTableReserved(data: any): Observable<any> {
    return this.http.post(this.URL_API + '/list/table/id', data);
  }

  totalReservedForTime(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/total/reserved/time', data);
  }

  removeAllForTimeExpired(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/remove/all/time/expired', data);
  }

  deleteByOrderAndClientAndState(query: any): Observable<any> {
    let httpParamsOptions: HttpParamsOptions = {
      fromObject: query
    }

    let httpParams = new HttpParams(httpParamsOptions);
    return this.http.delete(this.URL_API + '/delete/by/order/and/client/and/type', { params: httpParams });
  }
}