import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from "src/app/services/auth.service";
import { PaymentService } from "src/app/services/payment.service";

@Component({
  selector: 'app-wompi-checkout',
  templateUrl: './wompi-checkout.component.html',
  styleUrls: ['./wompi-checkout.component.scss'],
})
export class WompiCheckoutComponent implements OnInit {
  @Input() idPayment: string;
  @Input() idTable: string;
  @Output() wompiEvent = new EventEmitter<any>();

  card_list_hidden: boolean = true;
  card_new_hidden: boolean = true;
  loader = true;

  modal: any;
  user: any;
  payment: any;

  constructor(
    private authService: AuthService,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    //Recibimos id de payment
    //Traemos los datos del usuario
    this.user = this.authService.getUser();

    //Confirmamos que el payment exista en el backend y guardamos la respuesta en payment
    this.paymentService.show(this.idPayment).subscribe(response => {
      if (response.code == 200) {
        this.payment = response.data.show;
      }

    });

    //Si el user tiene un customer id podemos obtener las cards
    if (!this.user._client.customer_id) {
      this.loader = false;
      this.card_new_hidden = false;
    } else {
      this.card_list_hidden = false;
    }

  }

  hiddenLoader() {
    this.loader = false;
  }

  changeHidden(value: boolean, type: string) {
    this.loader = true;

    if (type == "list") {
      if (value == true) {
        this.hiddenLoader();
      }

      this.card_list_hidden = value;
      this.card_new_hidden = !value;
    }

    if (type == "new") {
      this.card_new_hidden = value;
      this.card_list_hidden = !value;
    }
  }

  emitPayment(event: any) {
    this.user = this.authService.getUser();

    this.wompiEvent.emit({
      wompi_response: event.wompi_response,
      payment: this.payment
    });
  }
}
