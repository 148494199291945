import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { WompiCheckoutCardListComponent } from "./wompi-checkout/wompi-checkout-card-list/wompi-checkout-card-list.component";
import { WompiCheckoutComponent } from "./wompi-checkout/wompi-checkout/wompi-checkout.component";
import { WompiCheckoutCardNewComponent } from "./wompi-checkout/wompi-checkout-card-new/wompi-checkout-card-new.component";
import { NgIconsModule} from "@ng-icons/core";



@NgModule({
  declarations: [
    WompiCheckoutCardListComponent,
    WompiCheckoutComponent,
    WompiCheckoutCardNewComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
  ],
  exports: [
    WompiCheckoutCardNewComponent,
    WompiCheckoutCardListComponent,
    WompiCheckoutComponent,
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class SharedModule {}
