import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loading: any;

  constructor(private loadingController: LoadingController) {}

  async open(message) {
    this.loading = await this.loadingController.create({
      message: message,
    });

    await this.loading.present();
  }

  dismiss() {
    this.loading.dismiss();
  }
}
