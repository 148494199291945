<ion-grid class="m-4">
  <div class="ion-text-center mb-2">
    <p>Tarjetas registradas: {{dataSourceCards?.length ?? 0}}</p>
    <p *ngIf="dataSourceCards?.length != 0">Desliza para escoger...</p>
  </div>
  <swiper-container effect="cards w-80">
    <swiper-slide *ngFor="let card of dataSourceCards" class="bg-gray-800 p-2 rounded-lg">
      <div class="">
        <div class="flex gap-2 justify-center m-2">
         <button class="bg-light-contrast dark:bg-dark-contrast shadow-lg px-4 py-2 rounded-full text-sm"  (click)="deleteCard(card)"> Eliminar tarjeta </button>
        </div>

        <div class="card card-front animate mx-10"  (click)="cardEvent(card)" >
          <div class="">
            <div class="brand" >{{ card.brand }}</div>
          </div>
          <div class="number">
            <div class="label"> Número de tarjeta</div>
            <span>XXXXXX{{ card.last_four }}</span>
          </div>
          <div class="owner-data">
            <div class="name">
              <div class="label">Titular</div>
              <div class="value">{{ card.card_holder }}</div>
            </div>
            <div class="validate">
              <div class="label">Válida hasta</div>
              <div class="value">{{ card.exp_month }}/{{ card.exp_year }}</div>
            </div>
          </div>
          <div class="flag">
            <img src="assets/icons/eh-logo-gray.svg" alt="mastercard">
          </div>
        </div>
        <div class="flex gap-2 justify-center m-2">
          <button class="text-white bg-primary px-4 py-2 shadow-lg rounded-full" (click)="cardEvent(card)"> Pagar</button>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>

  <ion-row *ngIf="dataSourceCards" class="btn-options">
    <ion-col class="content-center contents">
      <button
              class="text-white bg-primary hover:bg-primary focus:outline-none focus:ring-4 focus:ring-dark-primary font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2"
              size="small" (click)="goToNewCard()">
        Nueva tarjeta
      </button>
    </ion-col>
  </ion-row>

</ion-grid>


