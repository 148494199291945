import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  readonly URL_API = environment.apiEndpoint + '/order';
  constructor(private http: HttpClient) { }

  new(data): Observable<any> {
    return this.http.post(this.URL_API + '/new', data);
  }

  list(id: any): Observable<any> {
    return this.http.get(this.URL_API + '/list/' + `${id}`);
  }

  show(id: any): Observable<any> {
    return this.http.get(this.URL_API + '/show/' + `${id}`);
  }

  edit(data: any, id: string): Observable<any> {
    return this.http.patch(this.URL_API + '/edit/' + `${id}`, data);
  }


  quantityReservedForTime(data: any): Observable<any>{
    return this.http.post(this.URL_API + '/get/time/reserved', data);
  }

  availableTickets(eventId: string, categoryPrefix: string): Observable<any>{
    return this.http.get(this.URL_API + '/available/tickets/' + `${eventId}` + '/' + `${categoryPrefix}`);
  }


}

