import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})

/**
 * PAges-404 component
 */
export class Page404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = "https://eventhub.com.co/";
  }

}
