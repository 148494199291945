import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import {ToastController} from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(
    private toastrService: ToastrService,
    private toastController: ToastController,
  ) {}
  async open(
    message: string,
    position: any,
    duration: number = 2000,
    color: any = "dark"
  ) {
    const toast = await this.toastController.create({
      message: message,
      position: position,
      duration: duration,
      color: color,
    });

    toast.present();
  }

  async showError(message: string) {
    const toast = await this.toastrService.error(message, "Error", {
      positionClass: "toast-top-center",
      timeOut: 3000,
    });
  }

}
